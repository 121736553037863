<!--
	This is the Settings page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div id="settings">

    <a-card :bordered="false" class="card-product header-solid mb-24"  v-if="!$store.state.userInfo.is_partner">
      <div style="display: flex;flex-direction: column;align-items: center; justify-content: center;">
        <a-row type="flex" :gutter="[24,24]" style="align-items: center; justify-content: center;">

          <img src="images/promotion.png" style="height: 30%; width: 30%; margin-right: 30px">

          <div style="display: flex; flex-direction: column; align-items: start; justify-content: space-around; margin-left: 30px">
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-around; ">
              <h3>一次推广，持久收益</h3>
              <div>
                <p style="display: inline">自用省钱，推广赚钱！</p><p style="color:#ec575b; font-weight: bold; display: inline ">分佣最高可达70%！</p>
              </div>
            </div>
            <div style="margin-top: 20px">
              <a-button style="background-color:#ee7850; color: white;" shape="round" >联系客服加入</a-button>
            </div>
          </div>

        </a-row>

        <div style="display: flex;align-items: center; justify-content: center; background-image: url('images/text_bg.png'); background-size: contain;background-repeat: no-repeat;">
          <a-row type="flex" style="align-items: center; justify-content: start; width: 60%;">
            <a-row style="display: flex; flex-direction: column; align-items: start; justify-content: space-around;">
              <a-row style="display: flex; align-items: center; justify-content: start;margin-bottom: 0.5em">
                <img src="images/pinkstar.png" style="height: 10%; width: 10%; margin-right: 10px">
                <h5 style="margin-bottom: 0;">持续更新</h5>
              </a-row>
              <div>
                <p>根据交易所的规则，实时调整产品技术。在保持0分润跟单的基础上，不断研发新的产品功能，做有区别与交易所官方跟单产品。实现更精细化的跟单，同时确保产品功能的稳定性，降低用户的使用成本。</p>
              </div>
            </a-row>

            <a-row style="display: flex; flex-direction: column; align-items: start; justify-content: space-around;">
            <a-row style="display: flex; align-items: center; justify-content: start;margin-bottom: 0.5em">
              <img src="images/pinkstar.png" style="height: 10%; width: 10%; margin-right: 10px">
              <h5 style="margin-bottom: 0;">社群变现</h5>
            </a-row>
            <div>
              <p>帮助合伙人实现社群流量变现。配合交易所的推广返佣链接，提高合伙人给交易所拉新的竞争力，通过更多的交易量提高返佣金额，实现合伙人收益最大化。</p>
            </div>
          </a-row>

            <a-row style="display: flex; flex-direction: column; align-items: start; justify-content: space-around;">
            <a-row style="display: flex; align-items: center; justify-content: start;margin-bottom: 0.5em">
              <img src="images/pinkstar.png" style="height: 10%; width: 10%; margin-right: 10px">
              <h5 style="margin-bottom: 0;">运营赋能</h5>
            </a-row>
            <div>
              <p>可自定义跟单猿网站运营相关信息，通过网站提供的运营工具（如兑换码、优惠券）提高推荐转化率。</p>
            </div>
          </a-row>

            <a-row style="display: flex; flex-direction: column; align-items: start; justify-content: space-around;">
            <a-row style="display: flex; align-items: center; justify-content: start;margin-bottom: 0.5em">
              <img src="images/pinkstar.png" style="height: 10%; width: 10%; margin-right: 10px">
              <h5 style="margin-bottom: 0;">定制开发</h5>
            </a-row>
            <div>
              <p>所有合伙人都可以直接对接网站开发者，对某些特殊的需求和服务免费定制开发，比如更多的跟单渠道，以及站外推广工具等等。</p>
            </div>
          </a-row>

            <a-row style="display: flex; flex-direction: column; align-items: start; justify-content: space-around;">
              <a-row style="display: flex; align-items: center; justify-content: start;margin-bottom: 0.5em">
                <img src="images/pinkstar.png" style="height: 10%; width: 10%; margin-right: 10px">
                <h5 style="margin-bottom: 0;">收益透明</h5>
              </a-row>
              <div>
                <p>推广成功后，奖励立即到账。所有推广收益记录清晰可查，可随时提现。支持提现到交易所。</p>
              </div>
            </a-row>
        </a-row>
        </div>
      </div>
    </a-card>

    <a-tabs class="tabs-sliding" default-active-key="1" v-if="$store.state.userInfo.is_partner">

      <!-- 概况 Tab  -->
      <a-tab-pane key="1" tab="概况">

        <a-card class="header-solid" :bodyStyle="{padding: '40px', height: '200px',}">
          <a-row :gutter="[16,16]">
            <a-col :span="4">
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-around; height: 120px">
                <h5 class="font-semibold m-0">状态</h5>
                <a-button type="primary" shape="round" :size="size" >
                  {{ $store.state.userInfo.is_partner ? '永久激活' : '未激活'}}
                </a-button>
              </div>
            </a-col>
            <a-col :span="4">
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-around; height: 120px">
                <h5 class="font-semibold m-0">销售分成比例</h5>
                <h3 class="font-semibold m-0 text-gray-7">{{ first_ratio*100 }}%</h3>
              </div>
            </a-col>
            <a-col :span="4">
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-around; height: 120px">
                <h5 class="font-semibold m-0">下级分成比例</h5>
                <h3 class="font-semibold m-0 text-gray-7">{{ second_ratio*100 }}%</h3>
              </div>
            </a-col>
            <a-col :span="4">
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-around; height: 120px">
                <h5 class="font-semibold m-0">已提现</h5>
                <h5 class="font-semibold m-0 text-gray-7"><a-icon type="dollar" theme="twoTone" style="margin-right: 5px"/>{{ withdraw_amount }}</h5>
              </div>
            </a-col>
            <a-col :span="4">
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-around; height: 120px">
                <h5 class="font-semibold m-0">可提现</h5>
                <h5 class="font-semibold m-0 text-gray-7"><a-icon type="dollar" theme="twoTone" style="margin-right: 5px"/>{{ remain_amount }}</h5>
              </div>
            </a-col>
            <a-col :span="4">
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-around; height: 120px">
                <h5 class="font-semibold m-0 text-white">.</h5>
                <a-button type="primary">
                  提现
                </a-button>
              </div>
            </a-col>
          </a-row>
        </a-card>

        <a-row type="flex" :gutter="[24,24]" style="margin-top: 10px">

          <a-col :span="24" :lg="12">
            <a-card class="header-solid mb-24">
              <template #title>
                <h5 class="mb-0 font-semibold">网站设置</h5>
              </template>

              <a-card :bordered="false" class="shadow-0" :bodyStyle="{padding: '8px'}">
                <a-row type="flex" align="middle">
                  <a-col :span="18" style="display: flex; align-items: center; justify-content: space-around;">
                    <p class="font-semibold mb-0 ml-10">IP定价&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <a-input v-model:value="ip_price" :disabled="!ip_isEditable"  placeholder="输入自定义售价" prefix="$" size="small" suffix="USDT" style="width: 70%"/>
                  </a-col>
                  <a-col :span="6" class="ml-auto">
                    <a-button type="primary" size="small" class="ml-10 px-25 font-bold"  @click="handleButtonClick('ip_price')">{{ip_buttonText}}</a-button>
                  </a-col>
                </a-row>
              </a-card>

              <a-card :bordered="false" class="shadow-0" :bodyStyle="{padding: '8px'}">
                <a-row type="flex" align="middle">
                  <a-col :span="18" style="display: flex; align-items: center; justify-content: space-around;">
                    <p class="font-semibold mb-0 ml-10">VIP定价&nbsp;</p>
                    <a-input v-model:value="vip_price" :disabled="!vip_isEditable" placeholder="输入自定义售价" prefix="$" size="small" suffix="USDT" style="width: 70%"/>
                  </a-col>
                  <a-col :span="6" class="ml-auto">
                    <a-button type="primary" size="small" class="ml-10 px-25 font-bold"  @click="handleButtonClick('vip_price')">{{vip_buttonText}}</a-button>
                  </a-col>
                </a-row>
              </a-card>

              <a-card :bordered="false" class="shadow-0" :bodyStyle="{padding: '8px'}">
                <a-row type="flex" align="middle">
                  <a-col :span="18" style="display: flex; align-items: center; justify-content: space-around;">
                    <p class="font-semibold mb-0 ml-10">客服微信</p>
                    <a-input v-model:value="wx" :disabled="!wx_isEditable" placeholder="输入客服微信" size="small" style="width: 70%"/>
                  </a-col>
                  <a-col :span="6" class="ml-auto">
                    <a-button type="primary" size="small" class="ml-10 px-25 font-bold"  @click="handleButtonClick('wx')">{{wx_buttonText}}</a-button>
                  </a-col>
                </a-row>
              </a-card>

              <a-card :bordered="false" class="shadow-0" :bodyStyle="{padding: '8px'}">
                <a-row type="flex" align="middle">
                  <a-col :span="18" style="display: flex; align-items: center; justify-content: space-around;">
                    <p class="font-semibold mb-0 ml-10">客服电报</p>
                    <a-input v-model:value="telegram" :disabled="!telegram_isEditable" placeholder="输入客服电报" size="small" style="width: 70%"/>
                  </a-col>
                  <a-col :span="6" class="ml-auto">
                    <a-button type="primary" size="small" class="ml-10 px-25 font-bold"  @click="handleButtonClick('telegram')">{{telegram_buttonText}}</a-button>
                  </a-col>
                </a-row>
              </a-card>

              <a-card :bordered="false" class="shadow-0" :bodyStyle="{padding: '8px'}">
                <a-row type="flex" align="middle">
                  <a-col :span="18" style="display: flex; align-items: center; justify-content: space-around;">
                    <p class="font-semibold mb-0 ml-10">客服昵称</p>
                    <a-input v-model:value="nickname" :disabled="!nickname_isEditable" placeholder="输入客服昵称" size="small" style="width: 70%"/>
                  </a-col>
                  <a-col :span="6" class="ml-auto">
                    <a-button type="primary" size="small" class="ml-10 px-25 font-bold"  @click="handleButtonClick('nickname')">{{nickname_buttonText}}</a-button>
                  </a-col>
                </a-row>
              </a-card>
            </a-card>
          </a-col>

          <a-col :span="24" :lg="12">
            <a-card class="header-solid mb-24">
              <template #title>
                <h5 class="mb-0 font-semibold">推广链接</h5>
              </template>

              <a-card :bordered="false" class="bg-gray-3 shadow-0" :bodyStyle="{padding: '8px'}">
                <a-row type="flex" align="middle">
                  <a-col><p class="font-semibold mb-0 ml-10" ref="copyText">https://copyapes.com/sign-up?code={{ invite_code }}</p></a-col>
                  <a-col class="ml-auto">
                    <a-button size="small" type="danger" class="ml-10 px-25 font-bold" @click="copyToClipboard">复制</a-button>
                  </a-col>
                </a-row>
              </a-card>
              <p style="color:#ec575b; margin-bottom: 20px; margin-top: 10px">邀请码：{{ invite_code }}</p>

              <template>
                <a-timeline>
                  <a-timeline-item>Step1 分享推广链接</a-timeline-item>
                  <a-timeline-item>Step2 用户注册登录</a-timeline-item>
                  <a-timeline-item>Step3 用户关联成功</a-timeline-item>
                </a-timeline>
              </template>

            </a-card>
          </a-col>

        </a-row>

        <a-card class="header-solid mb-24">
            <template #title>
              <h5 class="mb-0 font-semibold">客服公告</h5>
            </template>

            <a-card :bordered="false" class="shadow-0" :bodyStyle="{padding: '8px', marginBottom: '15px'}">
              <a-textarea v-model:value="notice" placeholder="设置的客服公告，将展示在所有受邀用户的首页。可以填写自己的返佣链接等信息。" :rows="4" @blur="handleButtonClick('notice')" />
            </a-card>

          </a-card>

        <a-card class="header-solid">

          <a-row type="flex" style="margin: 30px 0">
            <a-col :span="24" :md="12" class="mx-auto">
              <h4 class="text-center">合伙人计划常见问题</h4>
<!--              <p class="text-center text-lg">Frequently Asked Questions</p>-->
            </a-col>
          </a-row>

          <a-row type="flex" style="margin-bottom: 30px">
            <a-col :span="24" :md="20" class="mx-auto">

              <a-collapse v-model="faq" :bordered="false" accordion>
                <a-collapse-panel key="1" header="分佣奖励如何计算？" :showArrow="false" class="collapse-header">
                  <p>
                    分佣奖励会按照用户实际支付的金额，将{{ first_ratio*100 }}%分配给上一级用户，将{{ second_ratio*100 }}%分配给上上一级用户。
                    用户的实际支付金额 = 上一级用户自定义售价 * 自定义折扣。
                  </p>
                  <a-icon slot="extra" :type="faq == 1 ? 'minus' : 'plus'" />
                </a-collapse-panel>
                <a-collapse-panel key="2" header="下级用户会和上级用户解除绑定关系吗？" :showArrow="false" class="collapse-header">
                  <p>
                    如果下级用户购买年费VIP，成功加入合伙人计划，会和其上级解除绑定关系。所有加入合伙人计划的用户，都可以按平台最低价购买服务自用，
                    且该消费将不会和上级用户分成，平台会从该笔消费中拿出{{ second_ratio*100 }}%，额外单独奖励给上上一级用户。
                    <br/>
                    如果下级用户没有选择加入合伙人计划，则永远不会和上级解除绑定关系，且下级用户的每一笔消费，上级用户都会分得{{ first_ratio*100 }}%，上上级用户也会分得{{ second_ratio*100 }}%。
                  </p>
                  <a-icon slot="extra" :type="faq == 2 ? 'minus' : 'plus'" />
                </a-collapse-panel>
                <a-collapse-panel key="3" header="为什么会有解除绑定机制？" :showArrow="false" class="collapse-header">
                  <p>
                    我们希望所有合伙人都可以通过合伙人计划获得长期的收益。长期收益的核心在于让自己的下级用户去发展更多的用户，去获得更多的下级销售分成，这部分的关系收益是永久的。
                    解除上下级的关系绑定，也是为了避免下级合伙人为了节约自己的成本，通过注册小号的形式去购买产品服务，再用自己的小号去做拉新推广，如此反而会让上级用户损失更多的下级销售分成，也会让二级销售返佣机制形同虚设变为一级销售返佣。
                  </p>
                  <a-icon slot="extra" :type="faq == 3 ? 'minus' : 'plus'" />
                </a-collapse-panel>
                <a-collapse-panel key="4" header="是否可以随意设置价格？" :showArrow="false" class="collapse-header">
                  <p>
                    考虑到币圈的特殊性，很多时候是通过"信息差"一次性赚取大额利润，所以我们允许所有合伙人定义产品售价，赚取合理的溢价。同时为了避免陷入恶性价格竞争，我们也设置了最低售价，保证了所有人的利润。
                    我们不建议设置过于高的售价，这样不利于用户未来持续复购我们的产品服务。我们的理念是让所有用户都能0分润跟单，获得最大的利润，过高的价格会增加用户购买服务的难度。
                  </p>
                  <a-icon slot="extra" :type="faq == 4 ? 'minus' : 'plus'" />
                </a-collapse-panel>
                <a-collapse-panel key="5" header="如何避免在推广的过程中相互竞争？" :showArrow="false" class="collapse-header">
                  <p>
                    使用自己的推广链接进行推广，用户注册后，会自动关联。网站会给用户展示上级自定义的产品售价和客服联系方式，增加用户信任。
                    同时你还可以生成体验兑换码进行私域推广，只有通过你的推广链接注册的用户才能使用你的兑换码，其他合伙人推广的用户无法使用。同样，也只有你的下级用户才能使用你设置的优惠折扣进行购买。
                  </p>
                  <a-icon slot="extra" :type="faq == 5 ? 'minus' : 'plus'" />
                </a-collapse-panel>
                <a-collapse-panel key="6" header="年费VIP售价如何计算？" :showArrow="false" class="collapse-header">
                  <p>
                    购买年费VIP时，系统会在自定义售价的基础上自动打9折，如有使用优惠券，则会进行折上折。如自定义VIP售价20U，则展示年费VIP售价216U，如再使用8折优惠券，则实际年费VIP售价216U * 0.8 = 172.8U。
                  </p>
                  <a-icon slot="extra" :type="faq == 6 ? 'minus' : 'plus'" />
                </a-collapse-panel>
                <a-collapse-panel key="7" header="如何提现分佣奖励？" :showArrow="false" class="collapse-header">
                  <p>
                    目前平台还处于早期阶段，如需提现，可联系官方客服，进行提现。提现会相应地收部分取手续费，手续费由每笔订单的交易网关和链上转账产生，无法避免。目前仅支持通过欧意交易所进行内部划转提现。
                  </p>
                  <a-icon slot="extra" :type="faq == 7 ? 'minus' : 'plus'" />
                </a-collapse-panel>
              </a-collapse>

            </a-col>
          </a-row>

        </a-card>


      </a-tab-pane>
      <!-- / 概况 Tab  -->

      <!-- 优惠码 Tab -->
      <a-tab-pane key="2" tab="优惠码">

        <a-row type="flex" :gutter="[24,24]">
          <a-col :span="24" :lg="12">

            <a-card class="header-solid mb-24">
              <template #title>
                <h5 class="mb-0 font-semibold">创建优惠码</h5>
              </template>

              <a-card :bordered="false" class="shadow-0" :bodyStyle="{padding: '8px'}">
                <a-row type="flex" align="middle">
                  <a-col :span="18" style="display: flex; align-items: center; justify-content: space-around;">
                    <p class="font-semibold mb-0 ml-10">优惠码折扣</p>
                    <a-input v-model:valus="discount" placeholder="eg. 70表示以自定义售价70%销售" size="small" suffix="%" style="width: 70%"/>
                  </a-col>
                  <a-col :span="6" class="ml-auto">
                    <a-button type="primary" size="small" class="ml-10 px-25 font-bold" @click="creatCoupon">创建</a-button>
                  </a-col>
                </a-row>
              </a-card>
            </a-card>

            <a-card class="header-solid mb-24">
              <template #title>
                <h5 class="mb-0 font-semibold">优惠码</h5>
              </template>
              <a-table
                  :columns="coupon_code_columns"
                  :data-source="coupon_code_list"
                  :row-selection="rowSelection"
                  :pagination="false"
                  style="border: 1px solid #ddd;border-radius: 8px;"
              >

                <template slot="coupon_code" slot-scope="coupon_code">
                  {{ coupon_code }}
                </template>

                <template slot="discount" slot-scope="discount">
                  {{ discount * 100 }}%
                </template>

                <template slot="delBtn" slot-scope="row">
                  <a-button type="link" :data-id="row.id" class="btn-edit" @click="deleteCoupon(row.id)">
                    删除
                  </a-button>
                </template>

              </a-table>
            </a-card>

          </a-col>
        </a-row>

      </a-tab-pane>
      <!-- / 优惠码 Tab -->

      <!-- 兑换码 Tab -->
      <a-tab-pane key="3" tab="兑换码">

        <a-row type="flex" :gutter="[24,24]">
          <a-col :span="24" :lg="12">

            <a-card class="header-solid mb-24">
              <template #title>
                <h5 class="mb-0 font-semibold">创建7天体验兑换码</h5>
              </template>

              <a-card :bordered="false" class="shadow-0" :bodyStyle="{padding: '8px'}">
                <a-row type="flex" align="middle">
                  <a-col :span="18" style="display: flex; align-items: center; justify-content: space-around;">
                    <p class="font-semibold mb-0 ml-10">兑换码个数</p>
                    <a-input v-model:valus="redeem_code_num" placeholder="eg. 10表示创建10个兑换码" size="small" suffix="个" style="width: 70%"/>
                  </a-col>
                  <a-col :span="6" class="ml-auto">
                    <a-button type="primary" size="small" class="ml-10 px-25 font-bold" @click="creatRedeemCode">创建</a-button>
                  </a-col>
                </a-row>
              </a-card>
            </a-card>

            <a-card class="header-solid mb-24">
              <template #title>
                <h5 class="mb-0 font-semibold">兑换码</h5>
              </template>
              <a-table
                  :columns="redeem_code_columns"
                  :data-source="redeem_code_list"
                  :row-selection="rowSelection"
                  :pagination="false"
                  style="border: 1px solid #ddd;border-radius: 8px;"
              >

                <template slot="redeem_code" slot-scope="redeem_code">
                  {{ redeem_code }}
                </template>

                <template slot="code_type" slot-scope="code_type">
                  {{ code_type }}
                </template>

              </a-table>
            </a-card>

          </a-col>
        </a-row>

      </a-tab-pane>
      <!-- / 兑换码 Tab -->

      <!-- 推广用户 Tab -->
      <a-tab-pane key="4" tab="推广用户">

        <a-card class="header-solid mb-24">
          <template #title>
            <h5 class="mb-0 font-semibold">推广用户</h5>
          </template>
          <a-table
              :columns="invite_columns"
              :data-source="invite_list"
              :row-selection="rowSelection"
              :pagination="{pageSize: 20,}"

          >

            <template slot="username" slot-scope="username">
              {{ username }}
            </template>

            <template slot="invite_type" slot-scope="invite_type">
              <a-tag class="tag-status" :class="invite_type === '一级邀请' ? 'ant-tag-green' : 'ant-tag-blue'">
                {{ invite_type }}
              </a-tag>
            </template>

            <template slot="invite_time" slot-scope="invite_time">
              {{ invite_time }}
            </template>

          </a-table>
        </a-card>

      </a-tab-pane>
      <!-- / 推广用户 Tab -->

      <!-- 提现明细 Tab -->
      <a-tab-pane key="5" tab="提现明细">

        <a-card class="header-solid mb-24">
          <template #title>
            <h5 class="mb-0 font-semibold">提现明细</h5>
          </template>
          <a-table
              :columns="withdraw_columns"
              :data-source="withdraw_list"
              :row-selection="rowSelection"
              :pagination="{pageSize: 20,}"

          >

            <template slot="amount" slot-scope="amount">
              {{ amount }}
            </template>

            <template slot="time" slot-scope="time">
              {{ time }}
            </template>

            <template slot="status" slot-scope="status">
              <a-tag class="tag-status" :class="status === '1' ? 'ant-tag-green' : 'ant-tag-red'">
                {{ status === '1' ? '成功' : '审核中'}}
              </a-tag>
            </template>

          </a-table>
        </a-card>

      </a-tab-pane>
      <!-- / 提现明细 Tab -->

      <!-- 收益流水 Tab -->
      <a-tab-pane key="6" tab="收益流水">

        <a-card class="header-solid mb-24">
          <template #title>
            <h5 class="mb-0 font-semibold">提现明细</h5>
          </template>
          <a-table
              :columns="buy_order_columns"
              :data-source="buy_order_list"
              :row-selection="rowSelection"
              :pagination="{pageSize: 20,}"

          >

            <template slot="order_id" slot-scope="order_id">
              {{ order_id }}
            </template>

            <template slot="price" slot-scope="price">
              {{ price }}
            </template>

            <template slot="order_price" slot-scope="order_price">
              {{ order_price }}
            </template>

            <template slot="rate" slot-scope="rate">
              {{ rate * 100 }}%
            </template>

            <template slot="amount" slot-scope="amount">
              {{ amount }}
            </template>

            <template slot="time" slot-scope="time">
              {{ time }}
            </template>

          </a-table>
        </a-card>

      </a-tab-pane>
      <!-- / 收益流水 Tab -->

    </a-tabs>

  </div>

</template>

<script>

import http from "../utils/http";
import store from "@/store";

const key = 'updatable';

const coupon_code_list = [
  {
    key: '1',
    coupon_code: '123456',
    discount: '70%',
  },
  {
    key: '2',
    coupon_code: '123456',
    discount: '70%',
  },
  {
    key: '3',
    coupon_code: '123456',
    discount: '70%',
  },
];

const coupon_code_columns = [
  {
    title: '优惠码',
    dataIndex: 'coupon_code',
    scopedSlots: { customRender: 'coupon_code' },
  },
  {
    title: '优惠折扣',
    dataIndex: 'discount',
    scopedSlots: { customRender: 'discount'}
  },
  {
    title: '',
    scopedSlots: {customRender: 'delBtn'},
    width: 50,
  }
]

const redeem_code_columns = [
  {
    title: '兑换码',
    dataIndex: 'redeem_code',
    scopedSlots: { customRender: 'redeem_code' },
  },
  {
    title: '兑换码类型',
    dataIndex: 'code_type',
    scopedSlots: { customRender: 'code_type'}
  },
]

const redeem_code_list = [
  {
    key: '1',
    redeem_code: '123456',
    code_type: '7天体验IP',
  },
  {
    key: '2',
    redeem_code: '123456',
    code_type: '7天体验IP',
  },
  {
    key: '3',
    redeem_code: '123456',
    code_type: '7天体验IP',
  },
];

const invite_columns = [
  {
    title: '用户名',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' },
  },
  {
    title: '邀请类型',
    dataIndex: 'invite_type',
    scopedSlots: { customRender: 'invite_type'}
  },
  {
    title: '邀请时间',
    dataIndex: 'invite_time',
    scopedSlots: { customRender: 'invite_time'}
  }
]

const invite_list = [
  {
    key: '1',
    username: '123456',
    invite_type: '1',
    invite_time: '2023-01-01',
  },
  {
    key: '2',
    username: '123456',
    invite_type: '2',
    invite_time: '2023-01-01',
  },
  {
    key: '3',
    username: '123456',
    invite_type: '1',
    invite_time: '2023-01-01',
  }
]

const withdraw_columns = [
  {
    title: '提现金额',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '提现时间',
    dataIndex: 'time',
    scopedSlots: { customRender: 'time'}
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status'}
  }
]

const withdraw_list = [
  {
    key: '1',
    amount: '123456',
    time: '2023-01-01',
    status: '1',
  },
  {
    key: '2',
    amount: '123456',
    time: '2023-01-01',
    status: '2',
  },
  {
    key: '3',
    amount: '123456',
    time: '2023-01-01',
    status: '1',
  }
]

const buy_order_columns = [
  {
    title: '订单ID',
    dataIndex: 'order_id',
    scopedSlots: { customRender: 'order_id' },
  },
  {
    title: '销售定价',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' },
  },
  {
    title: '订单实付',
    dataIndex: 'order_price',
    scopedSlots: { customRender: 'order_price' },
  },
  {
    title: '支付手续费',
    dataIndex: 'rate',
    scopedSlots: { customRender: 'rate' },
  },
 {
   title: '收益分成',
   dataIndex: 'amount',
   scopedSlots: { customRender: 'amount' },
 },
  {
    title: '购买时间',
    dataIndex: 'time',
    scopedSlots: { customRender: 'time' },
  }
]

const buy_order_list = [
  {
    key: '1',
    order_id: '123456',
    price: '123456',
    rate: 0.02,
    order_price: '123456',
    amount: '123456',
    time: '2023-01-01',
  },
  {
    key: '2',
    order_id: '123456',
    price: '123456',
    rate: 0.02,
    order_price: '123456',
    amount: '123456',
    time: '2023-01-01',
  }
]

export default {
  data() {
    return {
      // Active faq card
      faq: 1,

      coupon_code_list: [],
      coupon_code_columns: coupon_code_columns,

      redeem_code_list: [],
      redeem_code_columns: redeem_code_columns,

      invite_columns: invite_columns,
      invite_list: [],

      withdraw_columns: withdraw_columns,
      withdraw_list: withdraw_list,

      buy_order_columns: buy_order_columns,
      buy_order_list: [],

      invite_code: '', // 邀请码
      first_ratio: 0,  // 一级分成比例
      second_ratio: 0, // 二级分成比例

      withdraw_amount: 0, // 已提现金额
      remain_amount: 0, // 待提现金额

      ip_price: '', // ip 价格
      vip_price: '', // vip 价格
      wx: '', // 微信
      telegram: '', // telegram
      notice: '', // 公告

      isLoading: false, // 提交状态
      ip_isEditable: false, // ip 价格是否可编辑
      ip_buttonText: '修改', // ip 价格按钮文字
      vip_isEditable: false, // vip 价格是否可编辑
      vip_buttonText: '修改', // vip 价格按钮文字
      wx_isEditable: false, // 微信是否可编辑
      wx_buttonText: '修改', // 微信按钮文字
      telegram_isEditable: false, // telegram是否可编辑
      telegram_buttonText: '修改', // telegram按钮文字
      nickname: '', // 昵称
      nickname_isEditable: false, // 昵称是否可编辑
      nickname_buttonText: '修改', // 昵称按钮文字

      discount: '', // 折扣
      redeem_code: '', // 兑换码
      redeem_code_num: '', // 兑换码数量

    };
  },
  computed: {

  },
  created() {
    this.getUserData();
    // this.getLevel()
  },
  methods: {
    async getUserData() {
      // this.getLevel();
      // this.getCommissions();
      Promise.all([this.getLevel(), this.getCommissions(), this.getPartnerSet(), this.getCoupon(), this.getRedeemCode(), this.getInviteInfo(), this.getRevenue()])
          .then(() => {
            console.log('所有数据已成功加载');
          })
          .catch((error) => {
            console.error('加载用户数据失败:', error);
          });
    },
    async getLevel() {
      try {
        const response = await http.get('/api/partnerlevel/?token=' + localStorage.getItem("token"));
        if (response.data.code == 0) {
          this.invite_code = response.data.data.invite_code;
          this.first_ratio = response.data.data.first_ratio;
          this.second_ratio = response.data.data.second_ratio;
        } else {
          return;
        }
      } catch (error) {
        console.error('获取用户数据失败:', error);
      }
    },
    async getCommissions() {
      try {
        const response = await http.get('/api/commissions/?token=' + localStorage.getItem("token"));
        if (response.data.code == 0) {
          this.withdraw_amount = response.data.data.withdraw_amount;
          this.remain_amount = response.data.data.remain_amount;
        } else {
          return;
        }
      } catch (error) {
        console.error('获取用户数据失败:', error);
      }
    },
    async getPartnerSet() {
      try {
        const response = await http.get('/api/partnerset/?token=' + localStorage.getItem("token"));
        if (response.data.code == 0) {
          this.ip_price = response.data.data.ip_price;
          this.vip_price = response.data.data.vip_price;
          this.wx = response.data.data.wx;
          this.telegram = response.data.data.telegram;
          this.nickname = response.data.data.nickname;
          this.notice = response.data.data.notice;
        } else {
          return;
        }
      } catch (error) {
        console.error('获取用户数据失败:', error);
      }
    },

    async handleButtonClick(e) {
      try {
        if (e == 'ip_price') {
          if (this.ip_isEditable) {
            await this.updatePartnerSet(e);
          } else {
            this.ip_isEditable = true;
            this.ip_buttonText = '保存';
          }
        } else if (e == 'vip_price') {
          if (this.vip_isEditable) {
            await this.updatePartnerSet(e);
          } else {
            this.vip_isEditable = true;
            this.vip_buttonText = '保存';
          }
        } else if (e == 'wx') {
          if (this.wx_isEditable) {
            await this.updatePartnerSet(e);
          } else {
            this.wx_isEditable = true;
            this.wx_buttonText = '保存';
          }
        } else if (e == 'telegram') {
          if (this.telegram_isEditable) {
            await this.updatePartnerSet(e);
          } else {
            this.telegram_isEditable = true;
            this.telegram_buttonText = '保存';
          }
        } else if (e == 'nickname') {
          if (this.nickname_isEditable) {
            await this.updatePartnerSet(e);
          } else {
            this.nickname_isEditable = true;
            this.nickname_buttonText = '保存';
          }
        } else if (e == 'notice') {
          await this.updatePartnerSet(e);
        }
      } catch (error) {
        console.error("处理时发生错误:", error);
        this.$message.error({ content: '网络请求失败，请联系管理员', key, duration: 2 });
      }
    },
    async updatePartnerSet(e) {
      this.isLoading = true;
      this.$message.loading({ content: '设置中，请稍后...', key, duration: 0 });
      try {
        let data = {}
        if (e == 'ip_price'){
           if (this.ip_price < 10){
             this.$message.error({ content: 'IP价格不能小于10U', key, duration: 2 });
             return;
           }
           data = {
            ip_price: this.ip_price
          };
        } else if (e == 'vip_price'){
          if (this.vip_price < 20){
            this.$message.error({ content: 'VIP价格不能小于20U', key, duration: 2 });
            return;
          }
          data = {
            vip_price: this.vip_price
          };
        } else if (e == 'wx'){
          data = {
            wx: this.wx
          };
        } else if (e == 'telegram'){
          data = {
            telegram: this.telegram
          };
        } else if (e == 'nickname'){
          data = {
            nickname: this.nickname
          };
        } else if (e == 'notice'){
          data = {
            notice: this.notice.length > 0 ? this.notice : null
          };
        }
        const response = await http.patch('/api/partnerset/?token=' + localStorage.getItem("token"), data);

        if (response.data.code === 0) {
          this.$message.success({ content: '修改成功', key, duration: 2 });
        } else {
          this.$message.error({ content: response.data.error, key, duration: 2 });
        }

        if (response.data.code === 0) {
          if (e == 'ip_price'){
            this.ip_isEditable = false;
            this.ip_buttonText = '修改';
          } else if (e == 'vip_price'){
            this.vip_isEditable = false;
            this.vip_buttonText = '修改';
          } else if (e == 'wx'){
            this.wx_isEditable = false;
            this.wx_buttonText = '修改';
          } else if (e == 'telegram'){
            this.telegram_isEditable = false;
            this.telegram_buttonText = '修改';
          } else if (e == 'nickname'){
            this.nickname_isEditable = false;
            this.nickname_buttonText = '修改';
          }

          this.$message.success({ content: '修改成功', key, duration: 2 });
        } else {
          this.$message.error({ content: response.data.error, key, duration: 2 });
        }

      } catch (error) {
        console.error('更新用户数据失败:', error);
        this.$message.error({ content: '更新用户时发生错误', key, duration: 2 });
      } finally {
        this.isLoading = false;
      }
    },

    copyToClipboard() {
      // 获取 p 标签内容
      const textToCopy = this.$refs.copyText.textContent.trim();

      // 使用 Clipboard API 复制内容
      navigator.clipboard.writeText(textToCopy).then(() => {
        this.$message.success('复制成功！'); // 使用 Ant Design Vue 的消息提示
      }).catch(err => {
        console.error('复制失败:', err);
        this.$message.error('复制失败，请重试！');
      });
    },

    async creatCoupon() {
      this.isLoading = true;
      this.$message.loading({ content: '创建中，请稍后...', key, duration: 0 });
      if (this.discount < 60 || this.discount > 95){
        this.$message.error({ content: '折扣必须在60到95之间', key, duration: 2 });
        return;
      }

      const response = await http.post('/api/coupons/?token=' + localStorage.getItem("token"), {discount: this.discount});
      if (response.data.code === 0) {
        this.$message.success({ content: '创建成功', key, duration: 2 });
        this.discount = '';
        this.getCoupon()
      } else {
        this.$message.error({ content: response.data.error, key, duration: 2 });
      }

    },
    async getCoupon() {
      const response = await http.get('/api/coupons/?token=' + localStorage.getItem("token"));
      if (response.data.code === 0) {
        this.coupon_code_list = response.data.data;
      } else {
        return;
      }
    },
    async deleteCoupon(id) {
      this.$confirm({
        title: '确认删除',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          return new Promise((resolve, reject) => {
            http.delete("/api/del_coupon/" + id + "/?token=" + localStorage.getItem("token"))
                .then(() => {
                  this.getCoupon(); // 删除成功后调用 getCoupon
                  resolve();
                })
                .catch(err => {
                  reject(err); // 错误处理
                });
          });
        },
        onCancel() {
          console.log('删除操作取消');
      },
      });
    },

    async creatRedeemCode() {
      this.isLoading = true;
      this.$message.loading({ content: '创建中，请稍后...', key, duration: 0 });
      if (this.redeem_code_num > 10){
        this.$message.error({ content: '最多创建10个兑换码', key, duration: 2 });
        return;
      }

      const response = await http.post('/api/redeems/?token=' + localStorage.getItem("token"), {redeem_code_num: this.redeem_code_num});
      if (response.data.code === 0) {
        this.$message.success({ content: '创建成功', key, duration: 2 });
        this.redeem_code_num = '';
        this.getRedeemCode()
      } else {
        this.$message.error({ content: response.data.error, key, duration: 2 });
      }
    },
    async getRedeemCode() {
      const response = await http.get('/api/redeems/?token=' + localStorage.getItem("token"));
      if (response.data.code === 0) {
        this.redeem_code_list = response.data.data;
      } else {
        return;
      }
    },

    async getInviteInfo() {
      const response = await http.get('/api/inviteinfo/?token=' + localStorage.getItem("token"));
      if (response.data.code === 0) {
        this.invite_list = response.data.data;
      } else {
        return;
      }
    },

    async getRevenue() {
      console.log("getRevenue");
      try {
        const response = await http.get('/api/revenue/?token=' + localStorage.getItem("token"));
        if (response.data.code == 0) {
          this.buy_order_list = response.data.data;
        } else {
          return;
        }
      } catch (error) {
        console.error('获取用户数据失败:', error);
      }
    }


  },
};
</script>

<style lang="scss" scoped>
/* 使用深度选择器覆盖表头颜色 */
::v-deep .ant-table-thead > tr > th {
  background-color: #1890FF; /* 表头背景色设为蓝色 */
  color: white; /* 表头文字颜色设为白色 */
}

@import '../scss/base/_variables';
//@import '../scss/pages/pricing.scss';
.sliding-tab {
  text-align: center;
  width: 430px;
  margin: auto;
  background: #f8f9fa;
  border-radius: .75rem;
  position: relative;
  padding: 4px;

  ul {
    display: flex;
    position: relative;
    z-index: 2;
    padding: 0;
    margin: 0;
  }
  li.tab {
    display: block;
    flex: 1 1 0;
    border-radius: .5rem;
  }
  a {
    display: block;
    padding: .5rem 1rem;
    font-size: 16px;
    color: #344767;
  }
  .tab-bg {
    box-shadow: 0 1px 5px 1px #ddd;
    transition: transform .2s ease;
    transform: translate3d(0px, 0px, 0px);
    background: #fff;
    width: 211px;
    height: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 4px;
    z-index: 1;
    border-radius: 8px;
  }
}

.collapse-header {
  font-size: 18px;
  font-weight: 600;
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
  color: #67748e;
  color: $color-gray-8;
  //margin-bottom: 16px;
}
.collapse-header p {
  font-size: 16px;
  font-weight: 400;
}

</style>